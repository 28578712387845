<template>
    <div>
        <div class="p-d-flex p-jc-between">
            <p class="profile-title">Answer Bank - Tech</p>
            <div v-show="rootDirtyInd">
                <Button label="Cancel" class="p-button p-component p-button-outlined button" @click="cancelAnswerBank" />
                <Button label="Save" class="p-button p-component button" @click="saveAnswerBank" />
                <FloatingSaveCancel @save="saveAnswerBank" @cancel="cancelAnswerBank" />
            </div>
        </div>

        <Accordion :activeIndex="0" @click="onTabClick">
            <AccordionTab v-for="(item, index) in templates" :key="index" :header="item.category_name">
                <Wrapper
                    :parentItem="item"
                    v-model="inputs[item.category_name]"
                    :answers="answers"
                    @update:modelValue="updateAnswers"
                    @isValid="handleValidation"
                />
            </AccordionTab>
        </Accordion>
    </div>
</template>

<script>
import FloatingSaveCancel from '../components/common/FloatingSaveCancel.vue';
import CurrentUserService from '../service/CurrentUserService';
import AnswerBankService from '../service/AnswerBankService';
import { getAccount, getTemplatesFromLocalStorage } from '../service/common';
import Wrapper from '../components/answerBank/Wrapper';

window.setTimeout(function () {
    window.onpopstate = () => {
        this.sectionTrackGA();
    };
}, 1);
window.setTimeout(function () {
    window.onbeforeunload = () => {
        this.sectionTrackGA();
    };
}, 1);
export default {
    components: {
        FloatingSaveCancel,
        Wrapper,
    },
    name: 'AnswerBankTech',
    data() {
        return {
            activeTab: [0],
            saveInd: false,
            answerBankData: [],
            partnerId: null,
            isAdmin: false,
            lastClicked: new Date().getTime(),
            accordionLabel: null,
            templates: [],
            inputs: {},
            answers: [],
            newAnswerBankData: [],
            rootDirtyInd: false,
            fieldErrors: {},
            directoryId: 3,
            hasErrors: false,
        };
    },

    created() {
        this.userService = new CurrentUserService();
        this.answerBankService = new AnswerBankService();
        this.userIsACX = this.userService.marketplaceUser.userRole.roleKey.startsWith('acx:');
        this.isAdmin = getAccount();
    },

    async mounted() {
        this.getTemplatesForDirectory();
        await this.getAnswers();
        this.initializeInputs();
    },

    watch: {
        templates: {
            handler() {
                this.initializeInputs();
            },
            deep: true,
        },
    },

    methods: {
        handleValidation({ isValid, fieldName, categoryName }) {
            if (!this.fieldErrors[categoryName]) {
                this.fieldErrors[categoryName] = {};
            }
            this.fieldErrors = {
                ...this.fieldErrors,
                [categoryName]: {
                    ...this.fieldErrors[categoryName],
                    [fieldName]: isValid,
                },
            };

            // Checks if any field has validation errors
            this.hasErrors = Object.values(this.fieldErrors).every((category) =>
                Object.values(category).every((value) => value === true)
            );
        },
        initializeInputs() {
            // Convert answers into an object
            this.answers.forEach((category) => {
                category.data.forEach((answer) => {
                    this.answers[answer.id] = answer.json_value;
                });
            });

            // Initialize inputs with values from answers
            this.templates?.forEach((category) => {
                this.inputs[category.category_name] = {};
                category.data.forEach((field) => {
                    this.inputs[category.category_name][field.id] = this.answers[field.id] || '';
                });
            });
        },

        updateAnswers(updatedAnswers) {
            this.rootDirtyInd = true;

            for (let category of this.answers) {
                for (let field of category.data) {
                    if (updatedAnswers[field.id] !== undefined) {
                        field.json_value = updatedAnswers[field.id];
                    }
                }
            }
        },

        scrollToEl(element, offset) {
            window.scrollBy({
                top: element.getBoundingClientRect().top - offset,
                behavior: 'smooth',
            });
        },

        onTabClick(event) {
            let labelNames = new Set();
            this.templates.forEach((item) => {
                labelNames.add(item.category_name);
            });

            if (labelNames.has(event.target.innerText)) {
                setTimeout(() => {
                    this.scrollToEl(event.target, 70);
                }, 250);
                this.sectionTrackGA(),
                    this.accordionLabel === event.target.innerText
                        ? (this.accordionLabel = null)
                        : (this.accordionLabel = event.target.innerText);
            }
        },

        sectionTrackGA() {
            const timeNow = new Date().getTime();
            if (timeNow > this.lastClicked) {
                const duration = timeNow - this.lastClicked;
                const seconds = Math.floor(duration / 1000);
                this.lastClicked = timeNow;

                let sectionTrackInfo = {
                    section_time_spend: seconds,
                    section_name: this.accordionLabel,
                    timestamp: timeNow,
                };

                if (this.accordionLabel !== null) {
                    this.$gtag.event('answerbank_section_track', sectionTrackInfo);
                }
            }
        },

        postAnswerBank(payload) {
            this.answerBankService.postAnswerBank(payload, this.isAdmin).then((res) => {
                if (res.status === 200) {
                    this.rootDirtyInd = false;
                    this.$toast.add({ severity: 'success', summary: res.data, detail: '', life: 3000 });
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error while creating data', detail: res.data, life: 3000 });
                }
            });
        },

        cancelAnswerBank() {
            window.location.reload();
        },

        saveAnswerBank() {
            this.newAnswerBankData = this.answers
                .flatMap((category) => category.data)
                .reduce((acc, answer) => {
                    let value;
                    this.templates.forEach((templateCategory) => {
                        templateCategory.data.forEach((field) => {
                            if (field.id === answer.id) {
                                if (field.field_type === 'single_select' || field.field_type === 'single_select_search') {
                                    value = answer.json_value.name;
                                } else if (field.field_type === 'boolean_select') {
                                    value = answer.json_value?.value ?? null;
                                } else if (field.field_type === 'multi_select' || field.field_type === 'multi_select_search') {
                                    if (Array.isArray(answer.json_value)) {
                                        value = answer.json_value.map((item) => item.name);
                                    } else {
                                        value = [];
                                    }
                                } else if (field.field_type === 'tree_select') {
                                    value = Object.entries(answer.json_value)
                                        .filter(([, { checked, partialChecked }]) => checked && !partialChecked)
                                        .map(([key]) => key);
                                } else {
                                    value = answer.json_value;
                                }
                            }
                        });
                    });
                    acc[answer.id] = value;
                    return acc;
                }, {});

            const fileUploadFields = this.templates
                .flatMap((category) => category.data)
                .filter((field) => field.field_type === 'file_upload');

            const fileUploadFieldIds = fileUploadFields.map((field) => field.id);

            const payload = Object.fromEntries(
                Object.entries(this.newAnswerBankData).filter(([key]) => !fileUploadFieldIds.includes(key))
            );

            const callSave = this.hasErrors;
            callSave
                ? this.postAnswers(payload)
                : this.$toast.add({
                      severity: 'error',
                      summary: 'Error while creating data',
                      detail: 'Missing Information',
                      life: 3000,
                  });
        },

        getTemplatesForDirectory() {
            const templatesFromLS = getTemplatesFromLocalStorage('technology');
            this.templates = templatesFromLS;
            console.log('this.templates', this.templates);
        },

        async getAnswers() {
            const response = await this.answerBankService.getAnswers(this.directoryId);

            if (response?.status === 200) {
                //console.log('response.data.data.data', response.data.data.data);
                this.answers = response.data.data.data.map((category) => {
                    return {
                        ...category,
                        data: category.data.map((answer) => {
                            let modifiedAnswer = { ...answer };
                            this.templates?.forEach((templateCategory) => {
                                templateCategory.data.forEach((field) => {
                                    if (field.id === answer.id) {
                                        if (field.field_type === 'single_select' || field.field_type === 'single_select_search') {
                                            modifiedAnswer.json_value = { name: answer.json_value };
                                        } else if (field.field_type === 'boolean_select') {
                                            modifiedAnswer.json_value =
                                                (answer.json_value === 'true' || answer.json_value === true) 
                                                    ? { name: 'Yes', value: true }
                                                    : (answer.json_value === 'false' || answer.json_value === false) 
                                                        ? { name: 'No', value: false } 
                                                        : null
                                            //console.log("==> ", answer.json_value, modifiedAnswer.json_value);
                                        } else if (
                                            field.field_type === 'multi_select' ||
                                            field.field_type === 'multi_select_search'
                                        ) {
                                            if (Array.isArray(answer.json_value)) {
                                                modifiedAnswer.json_value = answer.json_value.map((value) => ({ name: value }));
                                            }
                                        } else if (field.field_type === 'tree_select') {
                                            const optionsObj = {};
                                            answer.json_value?.forEach((sl) => {
                                                optionsObj[sl] = { checked: true, partialChecked: false };
                                            });
                                            modifiedAnswer.json_value = optionsObj;
                                        } else if (
                                            field.field_type === 'number' ||
                                            field.field_type === 'short_text' ||
                                            field.field_type === 'decimal'
                                        ) {
                                            modifiedAnswer.json_value = answer.json_value === 'null' ? null : answer.json_value;
                                        } else {
                                            modifiedAnswer.json_value = answer.json_value === 'null' ? null : answer.json_value;
                                        }
                                    }
                                });
                            });
                            return modifiedAnswer;
                        }),
                    };
                });
            }
        },

        postAnswers(payload) {
            this.answerBankService.postAnswers(payload, this.directoryId).then((res) => {
                if (res.status === 200) {
                    this.$toast.add({ severity: 'success', summary: res.data.data, detail: '', life: 3000 });
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error while creating data', detail: res.data, life: 3000 });
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-accordion-header) {
    font-size: large;
}

::v-deep(.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link),
::v-deep(.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link):hover {
    background-color: rgb(228, 228, 228);
    font-weight: 500;
    margin-top: 1rem;
}

::v-deep(.p-button) {
    margin: 0 0 1.25rem 1.25rem;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
}
::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}

.profile-title {
    font-size: 2rem;
    font-weight: 500;
    margin-left: 1rem;
}
</style>
